import React, { useState } from 'react';
import { FaBars } from 'react-icons/fa';
import './FixedButton.css';

const FixedButton = () => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDrawer = () => {
    setIsOpen(!isOpen);
  };

  const closeDrawer = () => {
    setIsOpen(false);
  };

  return (
    <div className="fixed-button" onClick={toggleDrawer}>
      <div className="button-label" >
        <FaBars />
      </div>
      <div className={`drawer ${isOpen ? 'open' : ''}`}>
        <a href="#xoe-story" onClick={closeDrawer}>XOE’S STORY</a>
        <a href="#introduction" onClick={closeDrawer}>INTRODUCTION</a>
        <a href="#trait-descriptions" onClick={closeDrawer}>TRAIT DESCRIPTIONS</a>
        <a href="#roadmap" onClick={closeDrawer}>ROADMAP</a>
        <a href="#infographics" onClick={closeDrawer}>INFOGRAPHICS</a>
        <a href="#white-paper" onClick={closeDrawer}>WHITE PAPER</a>
      </div>
    </div>
  );
};

export default FixedButton;
