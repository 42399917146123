import React from 'react';
import Navbar from '../Navbar/Navbar';
import Bottom from '../Navbar/bottom';

function HeroSectionDesktop() {
 

  return (
    <div className=''>
      <Navbar />
     
 <Bottom/>
    </div>
  );
}

export default HeroSectionDesktop;
