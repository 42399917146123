import React from "react";
import { Grid } from "@mui/material";
import { FaSquareXTwitter } from "react-icons/fa6";
import logo from "./logo/logos.png";
import { FaTelegram } from "react-icons/fa6";
import "./Navbars.css";

const MobileNavbar = () => {
  return (
    <div className="padnav">
      <Grid container>
        <Grid item md={6} xs={6} sm={12} className="centeritall_start">
          <img src={logo} className="glowing-logos" alt="Logo" />
        </Grid>
        <Grid item md={6} xs={6} sm={12} className="centeritall_starts">
          <a href="https://x.com/XOE_ECOSYSTEM">
            <FaSquareXTwitter className="fcos" />
          </a>
          &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
          <a href="https://t.me/XOE_ECOSYSTEM ">
            <FaTelegram className="fcos" />
          </a>
          &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
        </Grid>
      </Grid>
    </div>
  );
};

export default MobileNavbar;
