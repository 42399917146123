import React from 'react';
import { Grid } from '@mui/material';
import { Link } from 'react-router-dom';

const documents = [
  { label: "XOE’S STORY", link: '/Documents#xoe-story' },
  { label: 'INTRODUCTION', link: '/Documents#introduction' },
  { label: 'TRAIT DESCRIPTIONS', link: '/Documents#trait-descriptions' },
  { label: 'ROADMAP', link: '/Documents#roadmap' },
  { label: 'INFOGRAPHICS', link: '/Documents#infographics' },
  { label: 'WHITE PAPER', link: '/Documents#white-paper' },
];

function Documents() {
  return (
    <div className='container' id='Documents'>
      <br /><br />
      <Grid container spacing={4}>
        {documents.map((doc, index) => (
          <Grid item md={6} lg={4} xs={12} sm={12} className='centeritall' key={index}>
            <Link to={doc.link}>
              <button className='docs_button' aria-label={doc.label}>
                {doc.label}
              </button>
            </Link>
          </Grid>
        ))}
      </Grid>
      <br /><br /><br /><br />
    </div>
  );
}

export default Documents;
