import React, { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import sidewalksVideo from "./LABSNAP.mp4"; // Update the path to your video file

const VideoBackground = () => {
  const [hovered, setHovered] = useState(false);
  const [cursorPos, setCursorPos] = useState({ x: 0, y: 0 });

  const handleMouseEnter = () => {
    setHovered(true);
  };

  const handleMouseLeave = () => {
    setHovered(false);
  };

  const handleMouseMove = (e) => {
    setCursorPos({ x: e.clientX, y: e.clientY });
  };

  return (
    <div className="video-background">
      <video autoPlay loop muted playsInline className="video">
        <source src={sidewalksVideo} type="video/mp4" />
        Your browser does not support the video tag.
      </video>
      <a
        href="/Home"
        className="center-link"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onMouseMove={handleMouseMove}
      ></a>
      <motion.div
        className="center-text"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 3, repeat: Infinity, repeatDelay: 3 }}
      ></motion.div>
      <AnimatePresence>
        {hovered && (
          <motion.div
            className="tooltip"
            initial={{ opacity: 0, scale: 0 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0 }}
            style={{ top: cursorPos.y, left: cursorPos.x }}
            transition={{ type: "spring", stiffness: 300 }}
          >
            ENTER
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
};

export default VideoBackground;
