import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import { ThemeProvider } from "@mui/material/styles";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { WagmiProvider } from "wagmi";
import { config } from "./config";
import theme from "./Theme";
import Home from "./pages/HomeX";
import Homes from "./Page/Home";
import Documents from "./Page/Docs";
import SmoothScroll from "./SmoothScroll";

import "./App.css";
import "rsuite/styles/index.less";

function ScrollToTop() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
}

function App() {
  const [target, setTarget] = useState(null);

  const handleScroll = (newTarget) => {
    setTarget(newTarget);
  };

  const queryClient = new QueryClient();

  return (
    <ThemeProvider theme={theme}>
      <WagmiProvider config={config}>
        <QueryClientProvider client={queryClient}>
          <Helmet>
            <title>ECOSYSTEM</title>
            <meta name="ECOSYSTEM" content="ECOSYSTEM" />
          </Helmet>
          <Router>
            <ScrollToTop />
            <SmoothScroll target={target} />
            <Routes>
              <Route path="/" element={<Home onScroll={handleScroll} />} />
              <Route path="/Home" element={<Homes onScroll={handleScroll} />} />
              <Route
                path="/Documents"
                element={<Documents onScroll={handleScroll} />}
              />
            </Routes>
          </Router>
        </QueryClientProvider>
      </WagmiProvider>
    </ThemeProvider>
  );
}

export default App;
