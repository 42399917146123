import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import Marquees from "./Marquee";
import DocsTopBar from "./DocsTopBar";
import FixedButton from "./FixedButton";
import xoestory from "./xoeStory/story.png";

import xoeintroduction1 from "./xoeIntroduction/INTRODUCTION1.png";
import xoeintroduction2 from "./xoeIntroduction/INTRODUCTION2.png";

import xoeroadmap from "./xoeroadmap/ROADMAP.png";

import xoeinphographics from "./xoeinphographics/INFOGRAPHICS.png";

import xoetrait1 from "./xoetrait/TRAITDESCRIPTION1.png";
import xoetrait2 from "./xoetrait/TRAITDESCRIPTION2.png";
import xoetrait3 from "./xoetrait/TRAITDESCRIPTION3.png";

import xoewhitepaper1 from "./xoewhitepaper/WHITEPAPER01.png";
import xoewhitepaper2 from "./xoewhitepaper/WHITEPAPER02.png";
import xoewhitepaper3 from "./xoewhitepaper/WHITEPAPER03.png";
import xoewhitepaper4 from "./xoewhitepaper/WHITEPAPER04.png";
import xoewhitepaper5 from "./xoewhitepaper/WHITEPAPER05.png";
import xoewhitepaper6 from "./xoewhitepaper/WHITEPAPER06.png";
import xoewhitepaper7 from "./xoewhitepaper/WHITEPAPER07.png";
import xoewhitepaper8 from "./xoewhitepaper/WHITEPAPER08.png";
import xoewhitepaper9 from "./xoewhitepaper/WHITEPAPER09.png";
import xoewhitepaper10 from "./xoewhitepaper/WHITEPAPER10.png";
import xoewhitepaper11 from "./xoewhitepaper/WHITEPAPER11.png";
import xoewhitepaper12 from "./xoewhitepaper/WHITEPAPER12.png";
import xoewhitepaper13 from "./xoewhitepaper/WHITEPAPER13.png";

function Docs() {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      setTimeout(() => {
        const element = document.getElementById(location.hash.substring(1));
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }, 0);
    }
  }, [location]);

  useEffect(() => {
    const saveScrollPosition = () => {
      sessionStorage.setItem("scrollPosition", window.scrollY);
    };
    window.addEventListener("beforeunload", saveScrollPosition);
    return () => {
      window.removeEventListener("beforeunload", saveScrollPosition);
    };
  }, []);

  useEffect(() => {
    const savedScrollPosition = sessionStorage.getItem("scrollPosition");
    if (savedScrollPosition) {
      window.scrollTo(0, parseInt(savedScrollPosition, 10));
      sessionStorage.removeItem("scrollPosition");
    }
  }, []);

  return (
    <div className="xoe_bg1">
      <DocsTopBar />
      <div className="container" id="xoe-story">
        <br />
        <br /> <br />
        <br />
        <div className="intro_documents">XOE’S STORY</div>
        <br />
        <div class="box">
          <img src={xoestory} style={{ width: "100%" }} />
        </div>
        <br />
      </div>

      <div className="container" id="introduction">
        <br />
        <div className="intro_documents">INTRODUCTION</div>
        <br />
        <div class="box">
          <img src={xoeintroduction1} style={{ width: "100%" }} />
        </div>
        <br />
        <div class="box">
          <img src={xoeintroduction2} style={{ width: "100%" }} />
        </div>
        <br />
      </div>
      <div className="container" id="trait-descriptions">
        <br />
        <br />
        <div className="intro_documents">TRAIT DESCRIPTIONS</div>
        <br />
        <div class="box">
          <img src={xoetrait1} style={{ width: "100%" }} />
        </div>
        <br />
        <div class="box">
          <img src={xoetrait2} style={{ width: "100%" }} />
        </div>

        <br />

        <div class="box">
          <img src={xoetrait3} style={{ width: "100%" }} />
        </div>

        <br />
      </div>

      <div className="container" id="roadmap">
        <br />
        <br />
        <div className="intro_documents">ROADMAP</div>
        <br />
        <div class="box">
          <img src={xoeroadmap} style={{ width: "100%" }} />
        </div>

        <br />
      </div>

      <div className="container" id="infographics">
        <br />
        <br />
        <div className="intro_documents">INFOGRAPHICS</div>
        <br />
        <div class="box">
          <img src={xoeinphographics} style={{ width: "100%" }} />
        </div>

        <br />
      </div>

      <div className="container" id="white-paper">
        <br />
        <br />
        <div className="intro_documents">WHITE PAPER</div>
        <br />
        <div class="box">
          <img alt="docs" src={xoewhitepaper1} style={{ width: "100%" }} />
        </div>
        <br />
        <div class="box">
          <img alt="docs" src={xoewhitepaper2} style={{ width: "100%" }} />
        </div>
        <br />
        <div class="box">
          <img alt="docs" src={xoewhitepaper3} style={{ width: "100%" }} />
        </div>
        <br />
        <div class="box">
          <img alt="docs" src={xoewhitepaper4} style={{ width: "100%" }} />
        </div>
        <br />
        <div class="box">
          <img alt="docs" src={xoewhitepaper5} style={{ width: "100%" }} />
        </div>
        <br />
        <div class="box">
          <img alt="docs" src={xoewhitepaper6} style={{ width: "100%" }} />
        </div>
        <br />
        <div class="box">
          <img alt="docs" src={xoewhitepaper7} style={{ width: "100%" }} />
        </div>
        <br />
        <div class="box">
          <img alt="docs" src={xoewhitepaper8} style={{ width: "100%" }} />
        </div>
        <br />
        <div class="box">
          <img alt="docs" src={xoewhitepaper9} style={{ width: "100%" }} />
        </div>
        <br />
        <div class="box">
          <img alt="docs" src={xoewhitepaper10} style={{ width: "100%" }} />
        </div>
        <br />
        <div class="box">
          <img alt="docs" src={xoewhitepaper11} style={{ width: "100%" }} />
        </div>
        <br />
        <div class="box">
          <img alt="docs" src={xoewhitepaper12} style={{ width: "100%" }} />
        </div>
        <br />
        <div class="box">
          <img alt="docs" src={xoewhitepaper13} style={{ width: "100%" }} />
        </div>
        <br /> <br /> <br /> <br />
      </div>
      <Marquees />
      <FixedButton />
    </div>
  );
}

export default Docs;
