import React from 'react'
import logo from './image/foot.png'
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaTelegram } from "react-icons/fa6";
function Footer() {
  return (
    <div className='footer_bg'>
        <br/><br/>
        <div className='centeritall'>
        <img src={logo} className='logo_footer'/>
        </div>
        <br/><br/>
        <div className='centeritall_flex'>

          <a href='https://x.com/XOE_ECOSYSTEM'>
<FaSquareXTwitter className='fco'/>

          </a>
          &nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a href='https://t.me/XOE_ECOSYSTEM '>
<FaTelegram className='fco'/>

          </a>
        
     
        </div>
       
    

        <br/><br/>
    </div>
  )
}

export default Footer