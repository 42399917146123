import React from 'react';
import { Grid } from '@mui/material';
import { FaSquareXTwitter } from "react-icons/fa6";
import logo from './logo/logos.png';
import { FaTelegram } from "react-icons/fa6";
import './Navbars.css';

const MobileNavbar = () => {
  return (
    <div className='padnavbo'>
  
      <Grid container>
        <Grid item md={6} xs={6} sm={12} className='centeritall_start'>
          
        </Grid>
        <Grid item md={6} xs={6} sm={12} className='centeritall_starts'>
         
          &nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;
          <a>
<FaTelegram className='fcosb'/>

          </a>&nbsp;&nbsp;
          &nbsp;&nbsp;&nbsp;&nbsp;
        </Grid>
      </Grid>
    </div>
  );
};

export default MobileNavbar;
