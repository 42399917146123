import React from 'react';
import Marquee from 'react-fast-marquee';

import M1 from './Marquee/White.png';
import M2 from './Marquee/Black.png';
import M3 from './Marquee/Blue.png';
import M4 from './Marquee/White.png';

function Marquees() {
  const images = [M1, M2, M3, M4];

  return (
    <div>
      <Marquee className="navbar_home_bg_head" style={{ padding: "10px" }}>
        {images.concat(images, images).map((image, index) => (
          <React.Fragment key={index}>
            <img src={image} className="marquee_img" alt={`Marquee Image ${index + 1}`} />
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
          </React.Fragment>
        ))}
      </Marquee>
    </div>
  );
}

export default Marquees;
