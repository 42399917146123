import React from "react";
import { useMediaQuery } from "@mui/material";

import HeroSectionDesktop from "../Desktop/HeroSectionDesktop.jsx";
import Bottom from '../Navbar/bottom.jsx';
import MobileNavbar from "../Navbar/MobileNavbar.jsx";
import VideoMobile from './VideobgMobile.jsx';
import VideoBackground from "./VideoBackground.jsx";
import Marquee from '../Page/Marquee.jsx'
function YourComponent() {
  // Define your custom breakpoints
  const isDesktop = useMediaQuery("(min-width: 1450px)");
  const isTablet = useMediaQuery("(min-width: 1100px) and (max-width: 1449px)");
  const isMobile = useMediaQuery("(max-width: 1099px)");

  return (
    <div>
      {(isDesktop || isTablet) && (
        <div>
          {/* Content for desktop and tablet view */}
          <VideoBackground />
          <HeroSectionDesktop />
        </div>
      )}
      {isMobile && (
        <div>
          {/* Content for mobile view */}
          <VideoMobile />
          <MobileNavbar />
          <a href="/Home" className="center-linkm"></a>
          <Marquee />
        </div>
      )}
    </div>
  );
}

export default YourComponent;
