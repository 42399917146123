import React, { useEffect } from 'react';
import { gsap } from 'gsap';
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';

gsap.registerPlugin(ScrollToPlugin);

const SmoothScroll = ({ target }) => {
  useEffect(() => {
    if (target) {
      gsap.to(window, {
        duration: 1,
        scrollTo: { y: target, autoKill: false },
        ease: 'power2.inOut'
      });
    }
  }, [target]);

  return null;
};

export default SmoothScroll;
