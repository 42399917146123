import React from 'react';
import { FaXTwitter } from 'react-icons/fa6';
import { FaTelegramPlane } from 'react-icons/fa';

function NavHome() {
  const navLinks = [
    { href: './', label: 'HOME' },
    { href: '#Documents', label: 'DOCUMENTS' },
    { href: '#Traits', label: 'TRAITS' },
    { href: 'https://x.com/XOE_ECOSYSTEM', label: <FaXTwitter className='nav_socials' /> },
    { href: 'https://t.me/XOE_ECOSYSTEM', label: <FaTelegramPlane className='nav_socials' /> },
  ];

  return (
    <nav className='navbar_home_bg'>
      <br />
      <div className='nav_elements'>
        {navLinks.map((link, index) => (
          <React.Fragment key={index}>
            &nbsp;&nbsp;
            <a href={link.href} className='nav_link'>
              {link.label}
            </a>
            &nbsp;&nbsp;
          </React.Fragment>
        ))}
      </div>
    </nav>
  );
}

export default NavHome;
