import React, { useState } from 'react';
import { Grid } from '@mui/material';
import { motion } from 'framer-motion';
import black from './Marquee/Black.png';
import NFT1 from './NFT/1.png';
import NFT2 from './NFT/2.png';
import NFT3 from './NFT/3.png';
import NFT4 from './NFT/4.png';
import NFT5 from './NFT/5.png';
import NFT6 from './NFT/6.png';
import NFT7 from './NFT/7.png';
import NFT8 from './NFT/8.png';
import NFT9 from './NFT/9.png';
import NFT10 from './NFT/10.png';

const nftImages = [NFT1, NFT2, NFT3, NFT4, NFT5, NFT6, NFT7, NFT8, NFT9, NFT10];

const NFTCard = ({ src, isActive, onHover, onMouseLeave }) => (
  <Grid item md={2.4} lg={2.4} xs={6} sm={6}>
    <motion.div 
      className={`card ${isActive ? 'active' : 'inactive'}`} 
      style={{ width: "100%", borderRadius: "10px" }}
      initial={{ opacity: 0, y: 100 }}
      whileInView={{ opacity: 1, y: 0 }}
      viewport={{ once: true, amount: 0.5 }}
      transition={{ duration: 0.5, ease: "easeOut" }}
      onHoverStart={onHover}
      onHoverEnd={onMouseLeave}
    >
      <br />
      <div className='container'>
        <img src={src} style={{ width: "100%", borderRadius: "10px" }} />
        <br /><br />
        <img src={black} style={{ width: "90%", position: "relative" }} />
      </div>
      <br />
    </motion.div>
  </Grid>
);

function XoeNFT() {
  const [hoveredIndex, setHoveredIndex] = useState(null);

  return (
    <div className='glassy_back'>
      <br /><br /><br /><br />
      <div className='container'>
        <Grid container spacing={1} className='card-container'>
          {nftImages.map((src, index) => (
            <NFTCard 
              key={index} 
              src={src} 
              isActive={index === hoveredIndex} 
              onHover={() => setHoveredIndex(index)}
              onMouseLeave={() => setHoveredIndex(null)}
            />
          ))}
        </Grid>
      </div>
      <br /><br /><br /><br />
      <style jsx>{`
        .glassy_back {
          position: relative;
        }
        .card-container {
          display: flex;
          flex-wrap: wrap;
        }
        .card {
          transition: filter 0.3s ease;
          filter: ${hoveredIndex === null ? 'none' : 'blur(5px)'};
        }
        .card.active {
          filter: none;
          z-index: 1; /* Ensure the active card is on top */
        }
      `}</style>
    </div>
  );
}

export default XoeNFT;
