import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Grid } from "@mui/material";
import { motion } from "framer-motion";
import htbr from "./image/xoerobo.png";

const fadeInUp = {
  hidden: { opacity: 0, y: 50 },
  visible: { opacity: 1, y: 0 },
};

const HowToBuy = () => {
  const location = useLocation();

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
      }
    }
  }, [location]);
  return (
    <div className="howtobuy_bg">
      <br />
      <div className="container" id="how_to_buy">
        <Grid container>
          <Grid item md={6} lg={6} xs={12} sm={12}>
            <motion.img
              src={htbr}
              style={{ width: "100%" }}
              className="top_buy_robot"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
            />
          </Grid>
          <Grid item md={6} lg={6} xs={12} sm={12}>
            <br />
            <br />
            <br />
            <motion.h1
              className="htb_head"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
            >
              HOW TO BUY
            </motion.h1>
            <br />
            <motion.h4
              className="htb_shead"
              data-text="CREATE A WALLET"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
            >
              CREATE A WALLET
            </motion.h4>
            <motion.p
              className="htb_uhead"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
            >
              Download Metamask or your wallet of choice from the app store or
              google play store for free. Desktop users, download the google
              chrome extension by going to Metamask.io
            </motion.p>
            <br />
            <br />
            <motion.h4
              className="htb_shead"
              data-text="GET SOME ETH OR SOL"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
            >
              GET SOME ETH OR SOL
            </motion.h4>
            <motion.p
              className="htb_uhead"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
            >
              Have ETH or SOL in your wallet to swap for XOE NFT. If you don’t
              have ETH or SOL, you can buy directly on the market, transfer from
              another wallet, or buy on another exchange and send it to your
              wallet.
            </motion.p>
            <br />
            <br />
            <motion.h4
              className="htb_shead"
              data-text="CONNECT YOUR WALLET"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
            >
              CONNECT YOUR WALLET
            </motion.h4>
            <motion.p
              className="htb_uhead"
              variants={fadeInUp}
              initial="hidden"
              whileInView="visible"
              viewport={{ once: true, amount: 0.5 }}
              transition={{ duration: 0.6, ease: "easeOut" }}
            >
              Ethereum: Click “Connect Wallet” and select MetaMask or another
              Ethereum wallet.
              <br />
              Solana: Click “Connect Wallet” and select Phantom or another
              Solana wallet.
              <br />
              Browse NFTs: Explore our NFT collection and select the one you
              want to buy.
              <br />
              Purchase the NFT: Click “Buy Now,” confirm the transaction in your
              wallet, and wait for confirmation.
            </motion.p>
            <br />
            <br />
          </Grid>
        </Grid>
      </div>
      <br />
      <br />
    </div>
  );
};

export default HowToBuy;
