import React from "react";
import headrobot from "./image/row.png";
import white from "./image/White.png";
import { Grid } from "@mui/material";
import { FaXTwitter } from "react-icons/fa6";
import { FaTelegramPlane } from "react-icons/fa";
import { Link } from "react-router-dom";

function HeadSection() {
  return (
    <div className="navbar_home_bg_head">
      <Grid container>
        <Grid item md={3} lg={3} xs={3} sm={3}>
          <img src={white} className="head_robot_top_white" alt="Head Robot" />
        </Grid>
        <Grid item md={6} lg={6} xs={6} sm={6}>
          <img src={headrobot} className="head_robot_top" alt="Head Robot" />
        </Grid>
        <Grid item md={3} lg={3} xs={3} sm={3}></Grid>
        <Grid item md={12} lg={12} xs={12} sm={12}>
          <div className="main_buttons">
            <Grid container>
              <Grid item md={4} lg={4} xs={4} sm={4}>
                <Link to="https://eth.xoe.xyz" className="main_links_buttons">
                  ETHEREUM NFTs
                </Link>
              </Grid>

              <Grid item md={4} lg={4} xs={4} sm={4}>
                <a href="#how_to_buy" className="main_links_buttons">
                  HOW TO BUY
                </a>
              </Grid>

              <Grid item md={4} lg={4} xs={4} sm={4}>
                <Link to="https://sol.xoe.xyz" className="main_links_buttons">
                  SOLANA NFTs
                </Link>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default HeadSection;
