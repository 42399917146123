import React from "react";
import { Grid } from "@mui/material";
import { FaArrowLeft, FaXTwitter } from "react-icons/fa6";
import ConnectWallect from "./ConnectWallect";
import { FaTelegramPlane } from "react-icons/fa";
import { Link } from "react-router-dom";
function DocsTopBar() {
  return (
    <div className="main_buttonsd">
      <Grid container>
        <Grid item md={4} lg={4} xs={4} sm={4}>
          <Link to="/Home">
            <a
              className="main_links_buttonsd"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <FaArrowLeft size={16} style={{ marginRight: "5px" }} /> BACK
            </a>
          </Link>
        </Grid>
        <Grid item md={4} lg={4} xs={4} sm={4}></Grid>
        <Grid item md={4} lg={4} xs={4} sm={4}>
          <div className="main_links_buttonsd">
            <>
              <a href="https://x.com/XOE_ECOSYSTEM" className="nav_link">
                <FaXTwitter className="nav_socials" />
              </a>
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
              <a href="https://t.me/XOE_ECOSYSTEM" className="nav_link">
                <FaTelegramPlane className="nav_socials" />
              </a>
            </>
          </div>
        </Grid>
      </Grid>
    </div>
  );
}

export default DocsTopBar;
