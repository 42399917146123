import React from "react";

import Navbar from "./Components/NavHome";
import HeadSection from "./Components/HeadSection";

import MediaPlayer from "./Components/MediaPlayer";
import Marquee from "./Components/Marquee";
import XoeNFT from "./Components/XoeNFT";

import TraitDescription from "./Components/TraitDescription";
import HowToBuy from "./Components/HowToBuy";
import Documents from "./Components/Documents";
import Footer from "./Components/Footer";
function Home() {
  return (
    <div>
      <Navbar />
      <HeadSection />
      <div className="back_big_robot">
        <MediaPlayer />
        <Marquee />
        <XoeNFT />
      </div>
      <TraitDescription />
      <HowToBuy />
      <Documents />
      <Footer />
      <div className="under_footer"></div>
    </div>
  );
}

export default Home;
