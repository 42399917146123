import React, { useState, useCallback, useRef } from 'react';
import ReactPlayer from 'react-player';
import { FaPlay, FaPause, FaVolumeUp, FaVolumeMute, FaSpinner } from 'react-icons/fa'; // Import FaSpinner icon
import xoevideo from './XOEVIDEO.mp4';

function MediaPlayer() {
  const [playing, setPlaying] = useState(false);
  const [ended, setEnded] = useState(false);
  const [volume, setVolume] = useState(0.8); // Default volume
  const [muted, setMuted] = useState(false);
  const [progress, setProgress] = useState(0);
  const [buffering, setBuffering] = useState(false); // Buffering state
  const playerRef = useRef(null);

  const handlePlayPause = () => {
    if (ended) {
      setEnded(false);
      setPlaying(true);
    } else {
      setPlaying(!playing);
    }
  };

  const handleEnded = useCallback(() => {
    setPlaying(false);
    setEnded(true);
  }, []);

  const handleProgress = useCallback(({ played }) => {
    setProgress(played * 100);
  }, []);

  const handleSeek = (e) => {
    const newValue = e.target.value;
    setProgress(newValue);
    playerRef.current.seekTo(newValue / 100);
  };

  const handleVolume = () => {
    setMuted(!muted);
    setVolume(muted ? 0.8 : 0);
  };

  const handleBuffer = () => {
    setBuffering(true);
  };

  const handleBufferEnd = () => {
    setBuffering(false);
  };

  return (
    <div className='glassy_back'> <br/>
      <div className=''>
        <div className='cards container'>
          <br/>
          <div className='video-container'>
            <ReactPlayer
              ref={playerRef}
              url={xoevideo}
              playing={playing}
              controls={false}
              width='100%'
              height='100%'
              className='react-player'
              onEnded={handleEnded}
              onProgress={handleProgress}
              onBuffer={handleBuffer}
              onBufferEnd={handleBufferEnd}
              volume={volume}
              muted={muted}
            />
            {buffering && <FaSpinner className='buffering-icon' />} {/* Show buffering icon */}
            <input
              type='range'
              min='0'
              max='100'
              value={progress}
              onChange={handleSeek}
              className='progress-slider'
            />
            <div className='controls'>
              <button className='play-pause-btn' onClick={handlePlayPause}>
                {ended || playing ? <FaPause /> : <FaPlay />}
              </button>
              <button className='volume-btn' onClick={handleVolume}>
                {muted ? <FaVolumeMute /> : <FaVolumeUp />}
              </button>
            </div>
          </div>
          <br/>
        </div>
      </div>
      <br/>
    </div>
  );
}

export default MediaPlayer;
