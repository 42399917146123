// components/TraitDescription.js
import React from "react";
import { useMediaQuery, useTheme } from "@mui/material";

import traits from "./image/Traits.png";
const TraitDescription = () => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("lg"));

  return smallScreen ? (
    <div className="container" id="Traits">
      <h1 className="trait_head" style={{ textAlign: "start" }}>
        {" "}
        TRAIT DESCRIPTIONS{" "}
      </h1>
      <img alt="traits" src={traits} style={{ width: "100%" }} />
      <br /> <br />
      <br />
    </div>
  ) : (
    <div
      className="container"
      id="Traits"
      style={{ position: "relative", bottom: "70px" }}
    >
      <h1 className="trait_head"> TRAIT DESCRIPTIONS </h1>
      <img alt="traits" src={traits} style={{ width: "100%" }} />
      <br /> <br />
      <br />
    </div>
  );
};

export default TraitDescription;
